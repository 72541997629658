'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from 'next-themes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1200000,
      suspense: true,
    },
  },
});

function Providers({ children }: React.PropsWithChildren) {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider attribute="class">{children}</ThemeProvider>
      <ReactQueryDevtools initialIsOpen={process.env.NEXT_PUBLIC_ENVIRONMENT === 'development'} />
    </QueryClientProvider>
  );
}

export default Providers;
