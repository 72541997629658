'use client';

import Image from 'next/image';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';

const MainHeader = () => {
  const { systemTheme, theme } = useTheme();
  const currentTheme = theme === 'system' ? systemTheme : theme;

  const [imageSrc, setImageSrc] = useState('/STHG-W.png');

  useEffect(() => {
    if (currentTheme === 'dark') {
      setImageSrc('/STHG-W.png');
    } else {
      setImageSrc('/STHG-D.png');
    }
  }, [currentTheme]);

  return (
    <div className="z-10 w-full bg-white dark:bg-darkContentBg">
      <div className="flex items-center justify-start px-3 py-1">
        <Image src={imageSrc} alt="STHG" width={257} height={56} />
        <h1 className="ml-2 border-l-2 border-solid border-headingBg px-6 dark:border-transparent">
          Cost-Contain Rx (CCRx)
        </h1>
      </div>
    </div>
  );
};

export default MainHeader;
