'use client';

import 'react-toastify/dist/ReactToastify.css';
import '../styles/globals.css';

import { configureScope } from '@sentry/nextjs';
import localFont from 'next/font/local';
import { useEffect } from 'react';

import MainHeader from '@/components/MainHeader';
import Providers from '@/containers/providers';
import ToastProvider from '@/containers/ToastProvider';
import { saveSession } from '@/utils/sessionStorage';

const inter = localFont({
  src: [
    {
      path: '../../public/fonts/Inter-Light.ttf',
      weight: '300',
    },
    {
      path: '../../public/fonts/Inter-Regular.ttf',
      weight: '400',
    },
    {
      path: '../../public/fonts/Inter-Medium.ttf',
      weight: '500',
    },
    {
      path: '../../public/fonts/Inter-SemiBold.ttf',
      weight: '600',
    },
    {
      path: '../../public/fonts/Inter-Bold.ttf',
      weight: '700',
    },
  ],
  variable: '--font-inter',
});

export default function RootLayout({ children }: { children: React.ReactNode }) {
  const transactionId = Math.random().toString(36).substring(2, 9);

  useEffect(() => {
    configureScope((scope) => {
      scope.setTag('transaction_id', transactionId);
      saveSession('X-Transaction-ID', transactionId);
    });
  }, []);

  return (
    <html lang="en">
      <body className={`${inter.variable} font-sans`}>
        <Providers>
          <ToastProvider>
            <div className="flex h-screen min-h-[600px] flex-col overflow-y-hidden">
              <div className="flex-0">
                <MainHeader />
              </div>
              {children}
            </div>
          </ToastProvider>
        </Providers>
      </body>
    </html>
  );
}
