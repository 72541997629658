'use client';

import { useTheme } from 'next-themes';
import type { Theme } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

export default function ToastProvider({ children }: { children: React.ReactNode }) {
  const { systemTheme, theme } = useTheme();
  const currentTheme = theme === 'system' ? systemTheme : theme;

  return (
    <>
      {children}
      {/* General behavior settings here... */}
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        pauseOnHover={false}
        closeOnClick
        draggable
        theme={currentTheme as Theme}
      />
    </>
  );
}
